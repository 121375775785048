import { httpAuthAdmin } from '../plugins/http'

class GroupService {
  get(id) {
    return httpAuthAdmin().get(`/groups/${id}`);
  }
  getAll() {
    return httpAuthAdmin().get(`/groups`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/groups`, data) : httpAuthAdmin().put(`/groups/${data.id}`, data);
  }
}

export default new GroupService();