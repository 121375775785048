var helper = {
    isInt: (value) => {
        return (
            !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value, 10))
        );
    },
};

export default helper;