import { http } from '../plugins/http'

class BlogService {
  getAll() {
    return http.get("/blogs");
  }
  get(slugUrl) {
    return http.get(`/blogs/${slugUrl}`);
  }
}

export default new BlogService();