<template>
  <v-footer absolute inset app height="150" width="auto" color="grey lighten-5">
    <v-container>
      <v-row no-gutters align="center" justify="center">
        <v-btn text to="/" :ripple="false" id="no-background-hover" style="text-transform: none !important"
          color="primary" large target="_blank">
          Disponible via
          <v-img class="ml-2" src="/static/monplanning.svg" width="150" max-width="150" max-height="40" />
        </v-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
  },
};
</script>