import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'
import helpers from './plugins/helpers';
import services from './plugins/services';
import store from './store'
// import { StripePlugin } from '@vue-stripe/vue-stripe';

import 'material-design-icons-iconfont/dist/material-design-icons.css'

const moment = require("moment");

Vue.prototype.$moment = moment;

Vue.use(helpers);
Vue.use(services);

Vue.use(VueMeta)

// const options = {
//   pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
//   apiVersion: 'v3',
//   locale: 'fr',
// };

// Vue.use(StripePlugin, options);

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: "G-9N31KHC6PH" }
  }, router);
}

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  store,
}).$mount('#app')


// document.addEventListener(() => {
//   root.$mount('#App')
//   document.dispatchEvent(new Event('vue-post-render'))
// })