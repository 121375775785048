import store from "../store/index"
import stringerhelper from "./stringhelper"
import i18n from "../i18n"

var helper = {
    showErrorCode: (code) => {
        store.dispatch(
            "snackbar/showErrorCode",
            code
        );
    },
    showSuccess: (message) => {
        store.dispatch("snackbar/showSuccess", message);
    },
    showSuccessI18N: (message) => {
        helper.showSuccess(stringerhelper.capitalizeI18N(message));
    },
    showError: (message) => {
        store.dispatch("snackbar/showError", message);
    },
    showErrorI18N: (message) => {
        helper.showError(stringerhelper.capitalizeI18N(message));
    },
    handleError: (error) => {
        console.dir(error)
        let message;

        if (error.response) {

            const errorCode = error.response.data?.code;
            const errorMessage = error.response.data?.message;
            const i18nMessage = i18n.t('errors.' + errorCode);

            message = errorCode ? i18nMessage != 'errors.' + errorCode ? i18nMessage : `${errorMessage} (${errorCode})` : errorMessage;
        }
        else if (error.code) {
            message = error.code;
        }
        else if (error.message) {
            message = error.message;
        } else {
            message = error;
        }

        //helper.showError(i18n.t('error-details', { error: message }));
        helper.showError(message);
    }
};

export default helper;