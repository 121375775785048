import AuthService from '../services/authservice';
import UserService from '../services/userservice';

//const user = JSON.parse(localStorage.getItem('user'));
const accessToken = localStorage.getItem('access_token');

const initialState = {
  accessToken: accessToken,
  user: null,
};

export const adminauth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit, dispatch, state }, user) {
      return AuthService.login(user).then(
        async response => {
          const user = response.data;

          if (user && user.accessToken) {
            localStorage.setItem('access_token', user.accessToken);

            await commit('loginSuccess', user.accessToken);

            await dispatch('fetchUser');

            return Promise.resolve(state.user);
          }

          commit('loginFailure');

          return Promise.reject('no token');
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    async loginFacebook({ commit, dispatch, state }, accessToken) {
      return AuthService.loginFacebook(accessToken).then(
        async response => {
          const user = response.data;

          if (user && user.accessToken) {
            localStorage.setItem('access_token', user.accessToken);

            await commit('loginSuccess', user.accessToken);

            await dispatch('fetchUser');

            return Promise.resolve(state.user);
          }

          commit('loginFailure');

          return Promise.reject('no token');
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    async fetchUser({ commit, state }) {
      if (!state.accessToken) return Promise.reject('No access token');

      if (state.user) {
        return Promise.resolve(state.user)
      }

      try {
        const data = await UserService.getMe();

        await commit('setUser', data.data);

        return Promise.resolve(data.data)
      }
      catch (error) {
        throw new Error(`fetchUser ${error}`);
      }
    },
    async resetAndFetchUser({ commit, dispatch, state }) {
      if (!state.accessToken) return Promise.reject('No access token');

      await commit('setUser', null);

      return dispatch('fetchUser');
    },
    async resetPassword({ commit }, email) {
      return AuthService.resetPassword(email).then(
        async () => {
          commit('resetPasswordSuccess');
          return Promise.resolve(email);
        },
        error => {
          commit('resetPasswordFailure');
          return Promise.reject(error);
        }
      );
    },
    async resetPasswordFromToken({ commit }, payload) {
      return AuthService.resetPasswordFromToken(payload.token, payload.password).then(
        async () => {
          commit('resetPasswordSuccess');
          return Promise.resolve(payload);
        },
        error => {
          commit('resetPasswordFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      localStorage.removeItem('access_token');

      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    registerFacebook({ commit }, payload) {
      return AuthService.registerFacebook(payload.user, payload.accessToken).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    // async firstLogin({ dispatch }, payload) {
    //   const newOrganization = await dispatch('organization/updateOrganization', payload.organization, { root: true });

    //   payload.site.organizationId = newOrganization.id;

    //   //const newSite = await dispatch('site/createOrUpdateSite', payload.site, { root: true });

    //   const newSite = await SiteService.create(payload.site);

    //   console.dir(newSite)

    //   return Promise.resolve();
    // }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    loginSuccess(state, accessToken) {
      state.accessToken = accessToken;
    },
    loginFailure(state) {
      state.accessToken =  null;
    },
    logout(state) {
      state.user = null;
      state.accessToken = false;
    },
    registerSuccess(state) {
      state.accessToken =  null;
    },
    registerFailure(state) {
      state.accessToken =  null;
    },
    resetPasswordSuccess(state) {
      state.accessToken =  null;
    },
    resetPasswordFailure(state) {
      state.accessToken =  null;
    }
  },
  getters: {
    getAccessToken(state) {
      return state?.accessToken;
    },
    isLogged(state) {
      return state?.accessToken;
    }
  }
};