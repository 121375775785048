<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on">
        Se connecter <v-icon class="ml-3">mdi-chevron-down</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-container>
        <v-form ref="form">
          <v-text-field
            :label="'e-mail ou code client'"
            v-model="client.login"
            prepend-icon="mdi-account"
          />

          <v-text-field
            v-model="client.password"
            :label="'mot de passe'"
            prepend-icon="mdi-lock"
            type="password"
            @keydown.enter="enterPressed()"
          />
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="handleLogin()" text>Se connecter</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
// import PublicOrganizationService from "@/services/publicorganizationservice";

export default {
  data() {
    return {
      client: {
        login: "",
        password: "",
      },
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  methods: {
    enterPressed() {
      this.handleLogin();
    },
    async handleLogin() {
      if (!this.$refs.form.validate()) return;

      if (this.client.login && this.client.password) {
        this.loading = true;

        this.$store.dispatch("publicorganizationauth/login", this.client).then(
          () => {
            console.dir("ok !");
          },
          (error) => {
            this.loading = false;
            
            switch (error.response?.data?.code) {
              case 'POL.password-undefined': this.$helpers.snackbar.showSuccess(this.$t('errors.' + error.response?.data?.code)); break;
              default: this.$helpers.snackbar.handleError(error);
            }
          }
        );
      }
    },
  },
};
</script>