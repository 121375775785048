import { httpAuthAdmin } from '../plugins/http'

class ReportService {
  getAllLessonMemberships({ search }) {
    var queryString = Object.keys(search).filter(key => search[key] != null).map(key => key + '=' + search[key]).join('&');

    return httpAuthAdmin().get(`/reports/lessonmemberships?${queryString}`);
  }
  exportAllLessonMemberships({ format, search }) {
    var queryString = Object.keys(search).filter(key => search[key] != null).map(key => key + '=' + search[key]).join('&');

    return httpAuthAdmin().get(`/reports/lessonmemberships/export/${format}?${queryString}`, {
      responseType: 'text',
      headers: { 'Content-Type': 'application/octet-stream' }
    });
  }
}

export default new ReportService();