import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" Se connecter "),_c(VIcon,{staticClass:"ml-3"},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VCard,[_c(VContainer,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":'e-mail ou code client',"prepend-icon":"mdi-account"},model:{value:(_vm.client.login),callback:function ($$v) {_vm.$set(_vm.client, "login", $$v)},expression:"client.login"}}),_c(VTextField,{attrs:{"label":'mot de passe',"prepend-icon":"mdi-lock","type":"password"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.enterPressed()}},model:{value:(_vm.client.password),callback:function ($$v) {_vm.$set(_vm.client, "password", $$v)},expression:"client.password"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.handleLogin()}}},[_vm._v("Se connecter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }