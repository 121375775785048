import { httpAuthAdmin } from '../plugins/http'

class UserService {
  getMe() {
    return httpAuthAdmin().get(`/users/me`);
  }
  get(id) {
    return httpAuthAdmin().get(`/users/${id}`);
  }
  getAll() {
    return httpAuthAdmin().get(`/users`);
  }
  pay(id, language) {
    return httpAuthAdmin().post(`/users/${id}/pay?language=${language}`);
  }
  inactivate(id) {
    return httpAuthAdmin().post(`/users/${id}/inactivate`);
  }
  simulateInactivate() {
    return httpAuthAdmin().get(`/users/simulate/inactivate`);
  }
  simulateAdd() {
    return httpAuthAdmin().get(`/users/simulate/add`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post("/users", data) : httpAuthAdmin().put(`/users/${data.id}`, data);
  }
}

export default new UserService();