import { httpAuthAdmin } from '../plugins/http'

class PaymentService {
  getAll({ search }) {
    var queryString = Object.keys(search).filter(key => search[key] != null).map(key => key + '=' + search[key]).join('&');

    return httpAuthAdmin().get(`/payments?${queryString}`);
  }
  export({ format, search }) {
    var queryString = Object.keys(search).filter(key => search[key] != null).map(key => key + '=' + search[key]).join('&');

    return httpAuthAdmin().get(`/payments/export/${format}?${queryString}`, {
      responseType: 'text',
      headers: { 'Content-Type': 'application/octet-stream' }
    });
  }
}

export default new PaymentService();