import { httpAuthAdmin } from '../plugins/http'

class PlanningService {
  get(id) {
    return httpAuthAdmin().get(`/planning/${id}`);
  }
  getAll(from, to, siteIds = []) {
    return httpAuthAdmin().get(`/planning?from=${from}&to=${to}&site_ids=${siteIds.join(',')}`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/planning`, data) : httpAuthAdmin().put(`/planning/${data.id}`, data);
  }
  cancel(planningId, { sendEmailPlanningCancellation = false, next = false, }) {
    return httpAuthAdmin().post(`/planning/${planningId}/cancel`, { send_email_planning_cancellation: sendEmailPlanningCancellation, next: next, });
  }
  generate(data) {
    return httpAuthAdmin().post(`/planning/generate`, data);
  }
  pick(data) {
    return httpAuthAdmin().post(`/planning/pick`, data);
  }
  delete(data, { next = false, }) {
    return httpAuthAdmin().delete(`/planning/${data.id}?next=${next}`);
  }
}

export default new PlanningService();