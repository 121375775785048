const moment = require("moment");
import i18n from '../i18n'

moment.locale(i18n.locale);

var helper = {
  getYearMonthDay: (date) => {
    if (date == null) return null;

    let year, month, day;

    if (typeof date === Date) {
      day = date.getDate();
      month = date.getMonth();
      year = date.getFullYear();
    } else if (date.includes('T') && date.includes('Z')) {
      const momentDate = moment(date);

      day = `${momentDate.format('DD')}`;
      month = momentDate.month() + 1;
      year = momentDate.year();
    } else {
      [year, month, day] = date.split("-");
    }

    return [year, month, day];
  },
  getDate: (date) => {
    if (date == null) return null;

    const [year, month, day] = helper.getYearMonthDay(date);

    return new Date(year, month - 1, day);
  },
  getDateDisplay: (date) => {
    if (date == null) return "";

    const [year, month, day] = helper.getYearMonthDay(date);

    return (
      day.padStart(2, "0") +
      "/" +
      month.toString().padStart(2, "0") +
      "/" +
      year
    );
  },
  getMonday: (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  },
  getMonthDisplay: (month) => {
    if (month == null) return "";

    return moment.months()[month - 1];
  },
  getFullDateDisplay: (date) => {
    if (date == null) return "";
    const d = (typeof date !== Date) ? new Date(date) : date;

    return `${d.getDate()} ${moment.months()[d.getMonth()]
      } ${d.getFullYear()}`;
  },
  getDayMonthDisplay: (date) => {
    date = helper.getDate(date);

    if (date == null) return '';

    return `${date.getDate()} ${moment.months()[date.getMonth()]}`;
  },
  getFullDateTimeDisplay: (date) => {
    const d = new Date(date);

    return `${d.getDate()} ${moment.months()[d.getMonth()]
      } ${d.getFullYear()} ${i18n.t('at')} ${d.getHours().toString().padStart(2, '0')}h${d.getMinutes().toString().padStart(2, '0')}`;
  },
  getTimeStringFromDate: (date) => {
    return date ? moment(date).format('HH:mm') : '00:00';
  },
  getTimeStringFromTimeString: (timeString) => {
    const [hours, minutes] = timeString.split(':');

    return `${hours}:${minutes}`;
  },
  addTimeStringToDate: (date, timeString) => {
    const [hours, minutes] = timeString.split(':');

    return moment(date).add({ hours: hours, minutes: minutes }).toDate();
  },
  getWeekdayName(weekday) {
    let name;

    switch (parseInt(weekday)) {
      case 1:
        name = "monday";
        break;
      case 2:
        name = "tuesday";
        break;
      case 3:
        name = "wednesday";
        break;
      case 4:
        name = "thursday";
        break;
      case 5:
        name = "friday";
        break;
      case 6:
        name = "saturday";
        break;
      case 7:
        name = "sunday";
        break;
      default:
        "none";
        break;
    }

    return i18n.t(name);
  },
};

export default helper;