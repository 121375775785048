<template>
  <v-container>
    <v-row no-gutters
      ><v-btn
        v-if="user && user.main"
        text
        class="pl-1 pr-1 font-weight-bold"
        to="/admin/my-organization"
        ><v-icon small left>mdi-cog</v-icon>{{ $t("my-activity") }}</v-btn
      ></v-row
    >
    <v-row no-gutters v-if="organizationUrl">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            class="pl-1 pr-1 text-caption"
            :to="'/' + organizationUrl"
            v-bind="attrs"
            v-on="on"
          >
            <!-- <v-icon small color="grey darken-1" class="mr-2">mdi-web</v-icon> -->
            <v-icon small left>mdi-open-in-new</v-icon>
            {{ $t("view-my-public-page") }}</v-btn
          >
        </template>
        <span>{{ fullOrganizationUrl }}</span>
      </v-tooltip>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrganizationSelector",
  data() {
    return {
      organization: null,
      user: null,
    };
  },
  computed: {
    organizationName() {
      return this.organization?.name;
    },
    fullOrganizationUrl() {
      // return this.organizationUrl
      //   ? `monplanning.be/${this.organizationUrl}`
      //   : null;
      return this.organizationUrl;
    },
    organizationUrl() {
      return this.organization?.url;
    },
  },
  async mounted() {
    this.user = await this.$store
      .dispatch("adminauth/fetchUser")
      .catch(() => {});

    let organization = await this.$store.dispatch(
      "organization/fetchOrganization"
    );

    this.organization = organization;
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "organization/setOrganization") {
        this.organization = this.$store.getters["organization/getOrganization"];
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>