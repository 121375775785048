import { httpAuthAdmin } from '../plugins/http'

class SiteService {
  getAll() {
    return httpAuthAdmin().get("/sites");
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post("/sites", data) : httpAuthAdmin().put(`/sites/${data.id}`, data);
  }
}

export default new SiteService();