import Vue from 'vue'
import Vuex from 'vuex'

import { publicorganizationauth } from './publicorganizationauthstore';
import { snackbar } from './snackbarstore';
import { organization } from './organizationstore';
import { adminauth } from './adminauthstore';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    publicorganizationauth,
    adminauth,
    snackbar,
    organization
  }
})
