// import Vue from 'vue'
import vuetify from "../plugins/vuetify"

var helper = {
    get mobileDialog() {
        return vuetify.framework.breakpoint.smAndDown;
    },
    get spacerDesktopClass() {
        return vuetify.framework.breakpoint.mdAndUp ? 'ml-4' : ''
    },
    get isMobile() {
        return this.mobileDialog;
    },
    get isDev() {
        return process.env.NODE_ENV === "development";
    },
};

export default helper;