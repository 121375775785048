<template>
  <div>
    <v-app-bar app hide-on-scroll color="white" flat>
      <v-container class="py-0 pl-0 fill-height">
        <v-row align="center" :justify="isPublicUrl ? 'end' : 'start'" class="ml-0">
          <v-app-bar-nav-icon v-if="user" @click="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title v-if="user" class="ml-2">
            <span>{{ title }}</span>
          </v-toolbar-title>

          <span class="text-right" v-else>
            <v-btn v-if="isPublicUrl" class="font-weight-medium" text to="/" color="primary">Retour à l'accueil</v-btn>
          </span>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                to="/"
                :ripple="false"
                id="no-background-hover"
                class="mr-0 pl-2"
                style="text-transform: none !important"
                color="primary"
                large
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="/static/monplanning.svg"
                  :width="mainLogoWidth"
                  :max-width="mainLogoWidth"
                  max-height="20"
                />
                <v-icon class="ml-4">mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>{{
              $helpers.string.capitalizeI18N("return-public-home")
            }}</span>
          </v-tooltip> -->
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed app class="orange lighten-5" v-if="user" :width="290">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col>
            <v-list>
              <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img lazy-src="@/assets/blank-profile-picture.png" :src="imageUrl"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>
                  <OrganizationSelector />
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="title">{{
                    user.firstName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="logOut" icon v-bind="attrs" v-on="on"><v-icon>mdi-logout-variant</v-icon></v-btn>
                    </template>
                    <span>{{ $helpers.string.capitalizeI18N("logout") }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list nav dense>
              <v-list-item-group v-model="group" active-class="deep-orange--text text--accent-2">
                <v-list-item link :to="getLink('dashboard')">
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.dashboard")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item link :to="getLink('my-calendar')">
                  <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.my-calendar")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item link :to="getLink('clients')">
                  <v-list-item-icon>
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.clients")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item link :to="getLink('memberships')">
                  <v-list-item-icon>
                    <v-icon>mdi-tag</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <!-- {{ $t("appbar.admin.memberships") }} -->
                    Abo. / Séances
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link :to="getLink('reports')">
                  <v-list-item-icon>
                    <v-icon>mdi-chart-bar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.reports")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider class="mb-1" />

                <!-- <v-list-item link :to="getLink('my-organization')">
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.my-organization")
                  }}</v-list-item-title>
                </v-list-item> -->

                <v-list-item link :to="getLink('lessons')">
                  <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.lessons")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item link :to="getLink('membership-types')">
                  <v-list-item-icon>
                    <v-icon>mdi-tag-text-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <!-- {{ $t("membership-types") }} -->
                    Types Abo. / Séances
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-icon class="shrink">
                      <v-icon>mdi-tag-text-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("membership-types")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    link
                    :to="getLink('membership-types/single-session')"
                  >
                    <v-list-item-title
                      ><v-icon small class="mr-2">mdi-numeric-1-box</v-icon
                      >{{
                        $t("appbar.admin.membership-types-single-session")
                      }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link :to="getLink('membership-types/card')">
                    <v-list-item-title
                      ><v-icon small class="mr-2">mdi-numeric</v-icon
                      >{{
                        $t("appbar.admin.membership-types-card")
                      }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link :to="getLink('membership-types/unlimited')">
                    <v-list-item-title
                      ><v-icon small class="mr-2">mdi-infinity</v-icon
                      >{{
                        $t("appbar.admin.membership-types-unlimited")
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list-group> -->

                <v-list-item link :to="getLink('sites')">
                  <v-list-item-icon>
                    <v-icon>mdi-crosshairs</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t("appbar.admin.sites")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block> Logout </v-btn>
        </div>
      </template> -->
          </v-col></v-row>
        <v-row align="end" justify="end">
          <v-col class="grow">
            <v-spacer />
            <v-container class="grow d-flex flex-column">
              <v-row align="end" justify="end" no-gutters class="grow d-flex flex-column">
                <v-col class="grow d-flex flex-column">
                  <v-spacer />
                  <!-- <LanguageSelector />  -->
                </v-col></v-row>
            </v-container>
          </v-col> </v-row></v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import OrganizationSelector from "@/components/Admin/Organization/OrganizationSelector";
// import LanguageSelector from "@/components/Admin/LanguageSelector";

export default {
  name: "App",

  components: {
    OrganizationSelector,
    // LanguageSelector,
  },

  data: () => ({
    drawer: true,
    group: "",
    barColor: "#4CAF50",
    imageUrl: null,
    user: null,
    title: "",
  }),
  computed: {
    isPublicUrl() {
      return (
        this.$route.fullPath.includes("/admin/register") ||
        this.$route.fullPath.includes("/admin/login") ||
        this.$route.fullPath.includes("/admin/reset-password")
      );
    },
    mainLogoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "150px";
        case "md":
          return "150px";
        case "lg":
          return "150px";
        case "xl":
          return "150px";
        default:
          return "150px";
      }
    },
    appBarVisible() {
      return !this.$route.meta.noAppBar;
    },
    footerVisible() {
      return !this.$route.meta.noFooter;
    },
    publicUrl() {
      return process.env.VUE_APP_PUBLIC_BASE_URL;
    },
  },
  watch: {
    $route() {
      this.handleRouteTitle();
    },
  },
  methods: {
    handleRouteTitle() {
      if (!this.$route.name) return;

      if (this.$route.name === "client-details") {
        return this.$services.client
          .get(this.$route.params.id)
          .then((data) => {
            this.title = this.$helpers.string.getClientFullName(data.data);
          })
          .catch(() => { });
      }

      switch (this.$route.name) {
        // case "membership-types":
        //   this.title = this.$helpers.string.capitalizeI18N(
        //     "appbar.admin." + this.$route.name + "-" + this.$route.params.type
        //   );
        //   break;
        default:
          this.title = this.$helpers.string.capitalizeI18N(
            "appbar.admin." + this.$route.name
          );
          break;
      }
    },
    getLink(target) {
      return this.$helpers.router.getAdminLink(target);
    },
    logOut() {
      this.$store.dispatch("adminauth/logout").then(() => {
        this.$helpers.router.go("login");
      });
    },
  },
  async mounted() {
    this.user = await this.$store
      .dispatch("adminauth/fetchUser")
      .catch(() => { });

    this.imageUrl = this.$store.getters["organization/getImageUrl"];

    this.handleRouteTitle();
  },
  created() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === "organization/setOrganization") {
        this.imageUrl = this.$store.getters["organization/getImageUrl"];
      }

      if (mutation.type === "adminauth/setUser") {
        this.user = await this.$store
          .dispatch("adminauth/fetchUser")
          .catch(() => { });
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>