import { http, httpAuthAdmin } from '../plugins/http'

class OrganizationService {
  get() {
    return httpAuthAdmin().get("/organizations");
  }
  update(data) {
    return httpAuthAdmin().put(`/organizations`, data);
  }
  getOverview() {
    return httpAuthAdmin().get("/organizations/overview");
  }
  sendReferralInvitation(email) {
    return httpAuthAdmin().post(`/organizations/referral/send-invitation`, { email: email });
  }
  simulatePrice(billPlan, periodicity, nbActiveUsers, date) {
    return http.get(`/organizations/simulate/price/${billPlan}/${periodicity}/${nbActiveUsers}/${date}`);
  }
  extend(billPlan, billPlanPeriodicity, language) {
    return httpAuthAdmin().post(`/organizations/extend/${billPlan}/${billPlanPeriodicity}?language=${language}`);
  }
  getPaymentConfirmation(id) {
    return httpAuthAdmin().get(`/organizations/payments/${id}/confirmation`);
  }
  getPaymentInvoice(id) {
    return httpAuthAdmin().get(`/organizations/payments/${id}/invoice`);
  }
  getPayments() {
    return httpAuthAdmin().get(`/organizations/payments`);
  }
  getTransactions() {
    return httpAuthAdmin().get(`/organizations/transactions`);
  }
  uploadImage(image) {
    let fd = new FormData()

    fd.append('image', image)

    return httpAuthAdmin().post('/organizations/upload/image', fd);
  }
}

export default new OrganizationService();