import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{directives:[{name:"show",rawName:"v-show",value:(_vm.organization),expression:"organization"}],attrs:{"app":"","color":"white","flat":""}},[_c(VContainer,{staticClass:"py-0 fill-height"},[_c(VRow,{staticClass:"ml-0",attrs:{"align":"center","no-gutters":""}},[_c(VBtn,{staticClass:"mr-10 pl-0",staticStyle:{"text-transform":"none !important"},attrs:{"text":"","to":_vm.getLink(),"ripple":false,"id":"no-background-hover","color":"primary","x-large":""}},[(_vm.imageUrl)?_c(VAvatar,{staticClass:"mr-2",attrs:{"size":"44"}},[_c(VImg,{attrs:{"src":_vm.imageUrl,"alt":"?","contain":""},on:{"error":function($event){_vm.imageUrl = null}}})],1):_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-calendar")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.organizationName))])],1),_c(VSpacer),_c(VSpacer,{staticClass:"d-lg-none"}),_c(VAppBarNavIcon,{staticClass:"d-lg-none",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VResponsive,{staticClass:"text-right d-none d-lg-flex mr-2"},[(_vm.user)?_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","to":{
            path: '/admin/',
          }}},[_vm._v("Admin")]):_vm._e(),_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","to":_vm.getLink(),"plain":"","color":"primary"}},[_vm._v("Planning")]),(!_vm.client)?_c('OrganizationLogin'):_vm._e(),(!_vm.client)?_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","color":"deep-orange","to":{
            name: 'OrganizationRegister',
            params: { organizationUrl: this.organizationUrl },
          }}},[_vm._v("S'inscrire")]):_vm._e(),(_vm.client)?_c('span',[_c(VBtn,{attrs:{"text":"","to":{
              name: 'OrganizationClientAccount',
              params: { organizationUrl: this.organizationUrl },
            }}},[_vm._v(_vm._s(_vm.client.firstName)+" "+_vm._s(_vm.client.lastName))]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.logOut()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-logout-variant")])],1)]}}],null,false,930360220)},[_c('span',[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("logout")))])])],1):_vm._e()],1)],1)],1)],1),_c(VNavigationDrawer,{attrs:{"temporary":"","right":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListItemGroup,{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[(_vm.user)?_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","to":{
            path: '/admin/',
          }}},[_vm._v("Admin")]):_vm._e()],1)],1),_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","to":_vm.getLink(),"plain":""}},[_vm._v("Planning")])],1)],1),(_vm.client)?_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"text":"","to":{
            name: 'OrganizationClientAccount',
            params: { organizationUrl: this.organizationUrl },
          }}},[_vm._v(_vm._s(_vm.client.firstName)+" "+_vm._s(_vm.client.lastName))])],1)],1):_vm._e(),(_vm.client)?_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.logOut()}}},[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("logout"))),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-logout-variant")])],1)],1)],1):_vm._e(),(!_vm.client)?_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"color":"primary","text":"","to":{
            name: 'OrganizationLogin',
            params: { organizationUrl: this.organizationUrl },
          }}},[_vm._v("se connecter")])],1)],1):_vm._e(),(!_vm.client)?_c(VListItem,[_c(VListItemContent,{staticClass:"d-flex justify-center"},[(!_vm.client)?_c(VBtn,{attrs:{"outlined":"","color":"deep-orange","to":{
            name: 'OrganizationRegister',
            params: { organizationUrl: this.organizationUrl },
          }}},[_vm._v("S'inscrire")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }