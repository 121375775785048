import { httpAuthAdmin } from '../plugins/http'

class ClientService {
  get(id) {
    return httpAuthAdmin().get(`/clients/${id}`);
  }
  getAll() {
    return httpAuthAdmin().get(`/clients`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/clients`, data) : httpAuthAdmin().put(`/clients/${data.id}`, data);
  }
}

export default new ClientService();