import OrganizationService from '../services/organizationservice'
import organizationhelper from '../helpers/organizationhelper'

const initialState = {
    organization: undefined,
};

export const organization = {
    namespaced: true,
    state: initialState,
    actions: {
        async fetchOrganization({ commit, state }) {
            if (state.organization) {
                return Promise.resolve(state.organization)
            }

            try {
                const data = await OrganizationService.get();

                await commit('setOrganization', data.data);

                return Promise.resolve(data.data)
            }
            catch (error) {
                throw new Error(`fetchOrganization ${error}`);
            }
        },
        async uploadImage({ commit }, file) {
            try {
                const { data } = await OrganizationService.uploadImage(file);

                await commit('setOrganization', data);

                return Promise.resolve(data);
            } catch (error) {
                throw new Error(error);
            }
        },
        async saveOrganization({ dispatch, state }, organization) {
            var newOrganization = await OrganizationService.update(organization);

            state.organization = undefined;

            await dispatch('fetchOrganization');

            return Promise.resolve(newOrganization.data);
        }
    },
    mutations: {
        setOrganization(state, organization) {
            state.organization = organization;
        }
    },
    getters: {
        getOrganization(state) {
            return state?.organization;
        },
        getImageUrl(state) {
            return organizationhelper.getImageUrl(state.organization);
        }
    }
}
