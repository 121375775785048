<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-progress-circular :size="100" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Loader",
  data: () => ({}),
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>