<template>
  <v-footer color="grey lighten-5">
    <v-container>
      <v-row no-gutters>
        <v-col class="shrink">
          <v-btn
            text
            :to="getLink('home')"
            :ripple="false"
            id="no-background-hover"
            class="mr-10 pl-0"
            style="text-transform: none !important"
            color="primary"
            large
          >
            <v-img
              src="/static/monplanning.svg"
              width="150"
              max-width="150"
              max-height="40"
            />
          </v-btn>
          <div class="body-2">
            monplanning est une application qui vous permet de gérer votre
            activité (planning, cours, clients et abonnements) et de bénéficier
            d'une page web pour que vos clients puissent réserver vos cours en
            ligne.
          </div>
        </v-col>
        <v-col class="ml-4">
          <div>
            <v-btn
              class="body-2"
              plain
              :to="getLink('home')"
              color="deep-orange"
              >Accueil</v-btn
            >
          </div>
          <div>
            <v-btn
              class="text-body-2"
              plain
              :to="getLink('features')"
              color="deep-orange"
              >Fonctionnalités</v-btn
            >
          </div>
          <div>
            <v-btn
              class="text-body-2"
              plain
              :to="getLink('pricing')"
              color="deep-orange"
              >Tarifs</v-btn
            >
          </div>

          <div class="text-overline mt-1 ml-4">illustrations storyset</div>
        </v-col>
        <v-col>
          <div>
            <v-btn
              class="text-body-2"
              plain
              :to="getLink('helpcenter')"
              color="deep-orange"
              >Centre d'aide</v-btn
            >
          </div>
          <div>
            <v-btn
              class="text-body-2"
              plain
              :to="getLink('terms')"
              color="deep-orange"
              >Termes et conditions</v-btn
            >
          </div>
          <div>
            <v-btn
              class="text-body-2"
              plain
              :to="getLink('contact')"
              color="deep-orange"
              >Contact</v-btn
            >
            <v-btn
              icon
              href="https://www.facebook.com/monplanning"
              target="_blank"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col class="text-body-2">
          <div class="font-weight-medium">Convient pour tous types d'activités :</div>
          <div>Sport (Yoga, Fitness, Aqua gym, Danse, ..)</div>
          <div>Atelier créatif (Poterie, Couture, Bricolage, ..)</div>
          <div>Animation (Enfants, Adultes, Ado, ..)</div>
          <div>Cours particuliers</div>
          <div>.. et bien d'autres !</div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
  methods: {
    getLink(target) {
      return this.$helpers.router.getPublicLink(target);
    },
  },
};
</script>