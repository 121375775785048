import { http } from '../plugins/http'

class AuthService {
  login(user) {
    return http.post('auth/login', {
      email: user.email,
      password: user.password
    });
  }

  loginFacebook(accessToken) {
    return http.post('auth/login/facebook', {
      accessToken: accessToken,
    });
  }

  resetPassword(email) {
    return http.post('auth/resetpassword', {
      email: email,
    });
  }

  resetPasswordFromToken(token, password) {
    return http.post(`auth/resetpassword`, {
      token: token,
      password: password,
    });
  }

  register(user) {
    return http.post('auth/register', {
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      password: user.password,
      organizationName: user.organizationName,
      promotionalCode: user.promotionalCode,
      referralCode: user.referralCode,
      billPlan: user.billPlan,
      phoneNumber: user.phoneNumber,
    });
  }

  registerFacebook(user, accessToken) {
    return http.post('auth/register/facebook', {
      organizationName: user.organizationName,
      promotionalCode: user.promotionalCode,
      referralCode: user.referralCode,
      billPlan: user.billPlan,
      accessToken: accessToken,
    });
  }
}

export default new AuthService();