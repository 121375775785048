import i18n from '../i18n'

class Rule {
    notEmpty(message) {
        return [v => !!v || (message !== undefined ? message : i18n.t('field-required'))];
    }
    atLeastOne(message) {
        return [(selected) => selected.length > 0 || (message !== undefined ? message : 'At least one item should be selected')];
    }
    email(message) {
        return [
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || (message !== undefined ? message : i18n.t('invalid-email'))
        ];
    }
}

export default new Rule();