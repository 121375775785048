import { httpAuthAdmin } from '../plugins/http'

class MembershipTypeService {
  get(id) {
    return httpAuthAdmin().get(`/membershiptypes/${id}`);
  }
  getAll() {
    return httpAuthAdmin().get(`/membershiptypes`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/membershiptypes`, data) : httpAuthAdmin().put(`/membershiptypes/${data.id}`, data);
  }
}

export default new MembershipTypeService();