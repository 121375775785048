<template>
  <v-app id="app" :style="{ background: $vuetify.theme.themes['light'].background }">
    <div v-cloak>
      <AdminAppBar v-if="currentPage == 'admin'" />
      <OrganizationAppBar v-else-if="currentPage == 'publicorganization'" />
      <PublicAppBar v-else-if="currentPage == 'public'" />

      <v-main class="grey lighten-5">
        <v-banner color="orange lighten-4" sticky v-show="showBannerValidity">
          {{ bannerMessageValidity }}
          <template v-slot:actions>
            <v-btn text color="deep-orange accent-4" to="/admin/my-organization">
              Prolonger l'aventure
            </v-btn>
          </template>
        </v-banner>
        <v-banner color="orange lighten-4" sticky v-show="showBannerMollie">
          <span v-html="bannerMessageMollie"></span>

          <template v-slot:actions>
            <v-btn text color="deep-orange accent-4" to="/admin/my-organization">
              Modifier les paramètres
            </v-btn>
          </template>
        </v-banner>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-sheet min-height="70vh" rounded="lg">
                <v-container class="pl-0 pr-0" fluid>
                  <router-view></router-view>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <Snackbar />

      <ConfirmDialog ref="confirm"></ConfirmDialog>

      <PublicFooter v-if="currentPage == 'public'" />

      <AdminFooter v-else-if="currentPage == 'admin'" />

      <OrganizationFooter v-else-if="currentPage == 'publicorganization'" />
    </div>

    <div v-if="!$data">Loading.....
      <Loader />
    </div>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Public/Snackbar";
import PublicFooter from "@/components/Public/PublicFooter";
import OrganizationFooter from "@/components/PublicOrganization/OrganizationFooter";
import OrganizationAppBar from "@/components/PublicOrganization/OrganizationAppBar";
import PublicAppBar from "@/components/Public/PublicAppBar";
import AdminAppBar from "@/components/Admin/AdminAppBar";
import AdminFooter from "@/components/Admin/AdminFooter";
import Loader from "@/components/Admin/Loader";
import ConfirmDialog from "@/components/Admin/ConfirmDialog";

export default {
  name: "App",
  components: {
    Snackbar,
    PublicFooter,
    OrganizationAppBar,
    PublicAppBar,
    AdminAppBar,
    AdminFooter,
    Loader,
    ConfirmDialog,
    OrganizationFooter,
  },
  data() {
    return {
      showApp: false,
      organization: null,
      chat: null,
    };
  },
  computed: {
    adminPage() {
      return this.$route.fullPath.includes("/admin/");
    },
    organizationPage() {
      return this.$route.meta.organizationPage ?? false;
    },
    currentPage() {
      if (!this.showApp) return "none";

      if (this.adminPage) {
        return "admin";
      } else if (this.organizationPage) {
        return "publicorganization";
      } else {
        return "public";
      }
    },
    showBannerMollie() {
      if (this.currentPage != "admin") return false;

      if (this.organization == null) return false;

      const mollie = this.organization.payment_types.find(x => x.id == 'mollie');
      
      if (!mollie) return false;

      if (this.organization.mollieApiKey.startsWith('test_')) return true;

      return false;
    },
    bannerMessageMollie() {
      if (!this.showBannerMollie) return "";

      return '<b>Une clé de test</b> est actuellement utilisée. Modifiez la ou désactivez <b>Mollie</b> dans les paramètres de votre activité.';
    },
    showBannerValidity() {
      if (this.currentPage != "admin") return false;

      if (this.organization == null) return false;

      if (this.organization.freeTrialValidityDateEnd != null) return true;

      if (
        this.$moment(this.organization.validityDateEnd).diff(
          this.$moment(),
          "days"
        ) <= 15
      )
        return true;

      return false;
    },
    bannerMessageValidity() {
      if (!this.showBannerValidity) return "";

      if (this.organization.freeTrialValidityDateEnd != null) {
        var a = this.$moment(this.organization.freeTrialValidityDateEnd);
        var b = this.$moment();
        const nbDays = a.diff(b, "days");

        if (nbDays == 0) return this.$t("free-trial-banner.zero-day-left");
        else if (nbDays == 1)
          return this.$t("free-trial-banner.one-day-left", {
            nbDays: Math.abs(nbDays),
          });
        else if (nbDays > 0)
          return this.$t("free-trial-banner.days-left", {
            nbDays: Math.abs(nbDays),
          });
        else
          return this.$t("free-trial-banner.expired", {
            nbDays: Math.abs(nbDays),
          });
      }

      const nbDays = this.$moment(this.organization.validityDateEnd).diff(
        this.$moment(),
        "days"
      );

      if (nbDays == 0) return this.$t("validity-banner.zero-day-left");
      else if (nbDays == 1)
        return this.$t("validity-banner.one-day-left", {
          nbDays: Math.abs(nbDays),
        });
      else if (nbDays >= 0 && nbDays <= 15)
        return this.$t("validity-banner.days-left", {
          nbDays: Math.abs(nbDays),
        });
      else if (nbDays < 0)
        return this.$t("validity-banner.expired", {
          nbDays: Math.abs(nbDays),
        });
      else return "";
    },
  },
  watch: {
    $route(to) {
      // console.dir(from);
      // console.dir(to);
      this.handleChat();

      this.handleRouteQuery(to);
    },
  },
  methods: {
    handleRouteQuery(route) {
      if (route.query.promotional_code) {
        localStorage.setItem("promotional_code", route.query.promotional_code);
      }

      if (route.query.referral_code) {
        localStorage.setItem("referral_code", route.query.referral_code);
      }
    },
    handleChat() {
      if (!this.showApp) return;

      //this.chat = document.querySelector(".fb_dialog");
      this.chat = window.Tawk_API;
      // this.chat = this.$Tawk;

      // console.dir(this.chat);

      if (this.currentPage == "public") {
        this.showChat();
      } else {
        this.hideChat();
      }
    },
    showChat() {
      if (!this.chat) return;
      // console.dir("show chat");
      if (this.chat.showWidget) this.chat.showWidget();

      //this.chat.style.display = "block";

      // if (typeof window !== "undefined") {
      //   if (typeof window.FB !== "undefined") {
      //     window.FB.CustomerChat.show(false);
      //   }
      // }
    },
    hideChat() {
      if (!this.chat) return;
      // console.dir("hide chat");
      if (this.chat.hideWidget) this.chat.hideWidget();

      //this.chat.style.display = "none";

      // if (typeof window !== "undefined") {
      //   if (typeof window.FB !== "undefined") {
      //     window.FB.CustomerChat.hide();
      //   }
      // }
    },
  },
  async mounted() {
    this.handleChat();

    try {
      this.organization = await this.$store
        .dispatch("organization/fetchOrganization")
        .catch(() => { });
    } catch (e) {
      console.dir(e);
    }

    const language = localStorage.getItem("language") ?? "fr";

    this.$i18n.locale = language;
    this.$vuetify.lang.current = language;

    this.$root.$confirm = this.$refs.confirm.open;

    this.showApp = true;
  },
  created() {
    this.showApp = false;

    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === "organization/setOrganization") {
        this.organization = this.$store.getters["organization/getOrganization"];
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  metaInfo() {
    const titleTranslationKey = `meta.titles.${this.$route.name}`;
    const routeTitle = this.$te(titleTranslationKey)
      ? this.$t(titleTranslationKey)
      : this.$t("meta.title");

    const descriptionTranslationKey = `meta.descriptions.${this.$route.name}`;
    const routeDescription = this.$te(descriptionTranslationKey)
      ? this.$t(descriptionTranslationKey)
      : this.$t("meta.description");

    const titleWithOrganization = this.organization ? `${this.organization.name} - ` : '';

    return {
      // titleTemplate: routeTitle,
      title: titleWithOrganization + routeTitle,
      meta: [
        {
          name: "description",
          content: routeDescription,
        },
        {
          name: 'keywords',
          content: "gestion de planning",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#no-background-hover::before {
  background-color: transparent !important;
}

.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}
</style>


<style>
.gradient {
  background: linear-gradient(to top,
      rgba(226, 226, 226, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 50%);
}

.gradient-inverse {
  background: linear-gradient(to bottom,
      rgba(226, 226, 226, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 50%);
}

.fill-background {
  background: rgba(226, 226, 226, 0.5);
}

[v-cloak] {
  display: none;
}

ul.feature {
  padding-top: 10px;
  list-style: none;
}

ul.feature li {
  background-image: url("/static/image/check-icon.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 26px;
  padding: 0px 0px 20px 40px;
  margin-left: -20px;
  font-weight: 500;
}

ul.network li {
  list-style: none;
  background-image: url("/static/image/check-icon.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 32px;
  padding: 0px 0px 30px 60px;
}

.container {
  max-width: 1300px;
}
</style>
