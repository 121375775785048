import axios from "axios";

export const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
        "Content-type": "application/json",
    }
});

export default http

export const httpAuthPublicOrganization = function (url) {
    const accessToken = localStorage.getItem(`access_token[${url}]`);
    
    return axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        headers: accessToken ? { Authorization: 'Bearer ' + accessToken, "Content-type": "application/json" } : { "Content-type": "application/json" }
    });
}

export const httpAuthAdmin = function () {
    const accessToken = localStorage.getItem('access_token');
    
    return axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        headers: accessToken ? { Authorization: 'Bearer ' + accessToken, "Content-type": "application/json" } : { "Content-type": "application/json" }
    });
}