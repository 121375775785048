import { VApp } from 'vuetify/lib/components/VApp';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{style:({ background: _vm.$vuetify.theme.themes['light'].background }),attrs:{"id":"app"}},[_c('div',{},[(_vm.currentPage == 'admin')?_c('AdminAppBar'):(_vm.currentPage == 'publicorganization')?_c('OrganizationAppBar'):(_vm.currentPage == 'public')?_c('PublicAppBar'):_vm._e(),_c(VMain,{staticClass:"grey lighten-5"},[_c(VBanner,{directives:[{name:"show",rawName:"v-show",value:(_vm.showBannerValidity),expression:"showBannerValidity"}],attrs:{"color":"orange lighten-4","sticky":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","color":"deep-orange accent-4","to":"/admin/my-organization"}},[_vm._v(" Prolonger l'aventure ")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.bannerMessageValidity)+" ")]),_c(VBanner,{directives:[{name:"show",rawName:"v-show",value:(_vm.showBannerMollie),expression:"showBannerMollie"}],attrs:{"color":"orange lighten-4","sticky":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","color":"deep-orange accent-4","to":"/admin/my-organization"}},[_vm._v(" Modifier les paramètres ")])]},proxy:true}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.bannerMessageMollie)}})]),_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VSheet,{attrs:{"min-height":"70vh","rounded":"lg"}},[_c(VContainer,{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)],1)],1)],1),_c('Snackbar'),_c('ConfirmDialog',{ref:"confirm"}),(_vm.currentPage == 'public')?_c('PublicFooter'):(_vm.currentPage == 'admin')?_c('AdminFooter'):(_vm.currentPage == 'publicorganization')?_c('OrganizationFooter'):_vm._e()],1),(!_vm.$data)?_c('div',[_vm._v("Loading..... "),_c('Loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }