import i18n from "../i18n"

function load(component) {
  return () => import(`../views/${component}.vue`)
}


const routes = [
  {
    //path: '/:locale(en|nl|fr)?',
    path: '/:locale(fr)?',
    name: 'public',
    component: {
      beforeRouteEnter: setLocale,
      beforeRouteUpdate: setLocale,
      render(c) { return c('router-view') }
    },
    children: [
      {
        name: 'home',
        path: '/',
        alias: ['', 'home', 'accueil'],
        component: load('Public/Home')
      },
      {
        path: 'about',
        alias: ['a-propos'],
        component: load('Public/About'),
      },
      {
        name: 'features',
        path: 'features',
        alias: ['fonctionnalites'],
        component: load('Public/Features'),
      },
      {
        name: 'pricing',
        path: 'pricing',
        alias: ['tarifs'],
        component: load('Public/Pricing')
      },
      {
        name: 'contact',
        path: 'contact',
        component: load('Public/Contact')
      },
      {
        name: 'helpcenter',
        path: 'helpcenter',
        alias: ['centre-d-aide'],
        component: load('Public/HelpCenter')
      },
      {
        name: 'terms',
        path: 'terms',
        alias: ['termes'],
        component: load('Public/Terms')
      },
      {
        name: 'blog',
        path: 'blog',
        alias: [],
        component: load('Public/Blog')
      },
    ]
  },
  {
    path: '/admin/',
    component: { render(c) { return c('router-view') } },
    meta: {
      adminPage: true,
    },
    children: [{
      path: '',
      name: 'Home',
      component: load('Admin/Home'),
      meta: {
        adminPage: true,
      },
    },
    {
      path: 'login',
      name: 'login',
      alias: ['se-connecter'],
      component: load('Admin/Login'),
      meta: {
        adminPage: true,
      },
    },
    {
      path: 'reset-password',
      name: 'reset-password',
      alias: ['reinitialiser-le-mot-de-passe'],
      component: load('Admin/ResetPassword'),
      meta: {
        adminPage: true,
      },
    },
    {
      path: 'register',
      name: 'register',
      alias: ['inscription'],
      component: load('Admin/Register'),
      meta: {
        adminPage: true,
      },
    },
    {
      path: 'my-calendar',
      name: 'my-calendar',
      alias: ['mon-planning'],
      component: load('Admin/MyCalendar'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'dashboard',
      name: 'dashboard',
      component: load('Admin/Dashboard'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'clients',
      name: 'clients',
      component: load('Admin/Clients'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'clients/:id',
      name: 'client-details',
      component: load('Admin/ClientDetails'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'first-login',
      alias: ['premiere-connexion'],
      component: load('Admin/FirstLogin'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'lessons',
      name: 'lessons',
      alias: ['cours'],
      component: load('Admin/Lessons'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'memberships',
      name: 'memberships',
      alias: ['seances-et-abonnements'],
      component: load('Admin/Memberships'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'sites',
      name: 'sites',
      component: load('Admin/Sites'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'my-organization',
      name: 'my-organization',
      alias: ['mon-organisation'],
      component: load('Admin/MyOrganization'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'membership-types',
      name: 'membership-types',
      component: load('Admin/MembershipTypes'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'membership-types/:type',
      name: 'membership-types',
      alias: ['seances-uniques'],
      component: load('Admin/MembershipTypes'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'reports',
      name: 'reports',
      alias: ['rapports'],
      component: load('Admin/Reports'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'user-confirmation/:id',
      name: 'user-confirmation',
      alias: ['confirmation-utilisateur/:id'],
      component: load('Admin/UserConfirmation'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    {
      path: 'organization-payment/:id',
      name: 'organization-payment',
      alias: ['paiement-organisation/:id'],
      component: load('Admin/OrganizationPayment'),
      meta: {
        authRequired: true,
        adminPage: true,
      }
    },
    ]
  },
  {
    path: '/:organizationUrl',
    component: load('PublicOrganization/OrganizationDetails'),
    meta: {
      organizationPage: true
    },
    children: [
      {
        path: '/:organizationUrl/',
        name: 'OrganizationHome',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationPlanning')
      },
      {
        path: '/:organizationUrl/login',
        name: 'OrganizationLogin',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationLogin')
      },
      {
        path: '/:organizationUrl/register',
        name: 'OrganizationRegister',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationRegister')
      },
      {
        path: '/:organizationUrl/my-account',
        name: 'OrganizationClientAccount',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationClientAccount')
      },
      {
        path: '/:organizationUrl/terms',
        name: 'OrganizationTermsAndConditions',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationTermsAndConditions')
      },
      {
        path: '/:organizationUrl/payment-confirmation/:paymentId',
        name: 'OrganizationPaymentConfirmation',
        meta: {
          organizationPage: true,
        },
        component: load('PublicOrganization/OrganizationPaymentConfirmation')
      },
      {
        path: '/:organizationUrl/reset-password',
        name: 'OrganizationResetPassword',
        component: load('PublicOrganization/ResetPassword'),
        meta: {
          organizationPage: true,
        },
      },
    ],
    // beforeEnter: (to, from, next) => {
    //     console.dir(to);
    //     console.dir(from);

    //     next();
    // },
    // redirect() {
    //   return process.env.VUE_APP_I18N_FALLBACK_LOCALE + '/' + i18n.t('routes.home')
    // }
  },
];

function setLocale(to, from, next) {
  let { locale } = to.params;
  if (!locale) {
    locale = 'fr';
  }

  // Do something with locale, check availability of messages etc.
  i18n.locale = locale;
  next();

}

export default routes;

// module.exports = routes;