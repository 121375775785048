import datehelper from './datehelper'

var helper = {
    getImageUrl: (organization) => {
        return organization ? `${process.env.VUE_APP_BASE_API_URL ?? ''}/resources/organizations/${organization.id}/${organization.id}?round=${new Date()}` : '';
    },
    getTransactionActionDescription: (organizationTransaction) => {

        switch (organizationTransaction.action) {
            case "extend-validity":
                return `Extension de la validité (${datehelper.getDateDisplay(
                    organizationTransaction.oldValidityDateEnd
                )} - ${datehelper.getDateDisplay(
                    organizationTransaction.newValidityDateEnd
                )})`;
            case "extend-validity-for-recommendee":
                return `Extension de la validité grâce au parrainage (${datehelper.getDateDisplay(
                    organizationTransaction.oldValidityDateEnd
                )} - ${datehelper.getDateDisplay(
                    organizationTransaction.newValidityDateEnd
                )})`;
            case "extend-validity-for-referral":
                return `Extension de la validité grâce au parrainage de ${organizationTransaction.recommendeeOrganization?.name ?? ''} (${datehelper.getDateDisplay(
                    organizationTransaction.oldValidityDateEnd
                )} - ${datehelper.getDateDisplay(
                    organizationTransaction.newValidityDateEnd
                )})`;
            case "create-user": return `Ajout de ${organizationTransaction.user?.firstName} ${organizationTransaction.user?.lastName}`;
            case "inactivate-user": return `Désactivation de ${organizationTransaction.user?.firstName} ${organizationTransaction.user?.lastName}`;
            default: return null;
        }
    }
};

export default helper;