import { httpAuthAdmin } from '../plugins/http'

class LessonService {
  get(id) {
    return httpAuthAdmin().get(`/lessons/${id}`);
  }
  getAll(siteIds = []) {
    return httpAuthAdmin().get(`/lessons?site_ids=${siteIds.join(',')}`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/lessons`, data) : httpAuthAdmin().put(`/lessons/${data.id}`, data);
  }
}

export default new LessonService();