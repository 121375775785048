import i18n from "../i18n";
import router from "../router"

var helper = {
    getI18NRoute: (route) => {
        const isAdmin = route.includes('admin.');

        //const url = `/${isAdmin ? 'admin/' : ''}${i18n.locale}/${i18n.t('routes.' + route)}`;
        const url = isAdmin ? `/admin/${route.replace('admin.', '')}` : '/' + route;

        return url;
    },
    push: (route) => {
        const newRoute = helper.getI18NRoute(route);

        if (router.currentRoute.path !== newRoute)
            router.push(newRoute);
    },
    go: (route) => {
        const newRoute = helper.getI18NRoute(route);

        if (router.currentRoute.path !== newRoute)
            router.go(newRoute);
    },
    getOrganizationLink: (target) => {
        return target ? `/${router.currentRoute.params.organizationUrl}/${target}` : `/${router.currentRoute.params.organizationUrl}`;
    },
    getLink: (target) => {
        const isAdmin = target.includes('admin.');

        return isAdmin ? this.getAdminLink(target.replace('admin.', '')) : this.getPublicLink(target);
    },
    getPublicLink: (target) => {
        switch (target) {
            case "login":
                return `/admin/login`;
            case "register":
                return `/admin/register`;
            default:
                return `/${i18n.locale}/${i18n.t('routes.' + target)}`;
        }
    },
    getAdminLink: (target) => {
        switch (target) {
            case "login":
                return `/admin/login`;
            case "register":
                return `/admin/register`;
            default:
                return `/admin/${target}`;
        }
    }
};

export default helper;