<template>
  <div>
    <v-app-bar app color="white" flat v-show="organization">
      <v-container class="py-0 fill-height">
        <v-row align="center" class="ml-0" no-gutters>
          <v-btn text :to="getLink()" :ripple="false" id="no-background-hover" class="mr-10 pl-0"
            style="text-transform: none !important" color="primary" x-large>
            <v-avatar v-if="imageUrl" size="44" class="mr-2">
              <v-img :src="imageUrl" alt="?" contain @error="imageUrl = null" />
            </v-avatar>
            <v-icon v-else class="mr-2">mdi-calendar</v-icon>

            <!-- <span v-show="$vuetify.breakpoint.smAndUp">Planning de </span> -->
            <span class="ml-1">{{ organizationName }}</span></v-btn>

          <v-spacer></v-spacer>

          <v-spacer class="d-lg-none" />

          <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-lg-none"></v-app-bar-nav-icon>

          <v-responsive class="text-right d-none d-lg-flex mr-2">
            <v-btn text :to="{
              path: '/admin/',
            }" class="ml-2" v-if="user">Admin</v-btn>
            <!-- <LanguageSelector /> -->

            <v-btn text :to="getLink()" class="ml-2" plain color="primary">Planning</v-btn>

            <OrganizationLogin v-if="!client" />

            <v-btn outlined color="deep-orange" :to="{
              name: 'OrganizationRegister',
              params: { organizationUrl: this.organizationUrl },
            }" class="ml-2" v-if="!client">S'inscrire</v-btn>
            <span v-if="client">
              <v-btn text :to="{
                name: 'OrganizationClientAccount',
                params: { organizationUrl: this.organizationUrl },
              }">{{ client.firstName }} {{ client.lastName }}</v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="logOut()" icon v-bind="attrs" v-on="on"><v-icon>mdi-logout-variant</v-icon></v-btn>
                </template>
                <span>{{ $helpers.string.capitalizeI18N("logout") }}</span>
              </v-tooltip></span>
          </v-responsive>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary right app>
      <v-list>
        <v-list-item-group v-model="group">
          <v-list-item>
            <v-list-item-content class="d-flex justify-center"><v-btn text :to="{
              path: '/admin/',
            }" class="ml-2" v-if="user">Admin</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="d-flex justify-center"><v-btn text :to="getLink()" class="ml-2"
                plain>Planning</v-btn></v-list-item-content>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-content class="d-flex justify-center"
              >
              <LanguageSelector />
              </v-list-item-content>
          </v-list-item> -->

          <v-list-item v-if="client">
            <v-list-item-content class="d-flex justify-center"><v-btn text :to="{
              name: 'OrganizationClientAccount',
              params: { organizationUrl: this.organizationUrl },
            }">{{ client.firstName }} {{ client.lastName }}</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item v-if="client">
            <v-list-item-content class="d-flex justify-center"><v-btn @click="logOut()" text>{{
              $helpers.string.capitalizeI18N("logout")
            }}<v-icon class="ml-2">mdi-logout-variant</v-icon></v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!client">
            <v-list-item-content class="d-flex justify-center"><v-btn color="primary" text :to="{
              name: 'OrganizationLogin',
              params: { organizationUrl: this.organizationUrl },
            }">se connecter</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!client">
            <v-list-item-content class="d-flex justify-center"><v-btn outlined color="deep-orange" :to="{
              name: 'OrganizationRegister',
              params: { organizationUrl: this.organizationUrl },
            }" v-if="!client">S'inscrire</v-btn></v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import LanguageSelector from "@/components/PublicOrganization/LanguageSelector";
import OrganizationLogin from "@/components/PublicOrganization/OrganizationLogin";
import PublicOrganizationService from "@/services/publicorganizationservice";

export default {
  components: {
    // LanguageSelector,
    OrganizationLogin,
  },
  data() {
    return {
      organization: null,
      client: null,
      user: null,
      drawer: false,
      group: null,
      imageUrl: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
    organizationName() {
      return this.organization?.name;
    },
  },
  async mounted() {
    try {
      const organization = await PublicOrganizationService.get(
        this.organizationUrl
      );

      this.organization = organization.data;

      this.client = await this.$store
        .dispatch("publicorganizationauth/fetchClient")
        .catch(() => { });

      this.user = await this.$store
        .dispatch("adminauth/fetchUser")
        .catch(() => { });

      console.log('get image url', this.organization, this.$helpers.organization.getImageUrl(this.organization))
      this.imageUrl = this.$helpers.organization.getImageUrl(this.organization);
    } catch (error) {
      console.dir(error);
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("publicorganizationauth/logout").then(() => {
        this.$router.push("/" + this.organizationUrl + "/");
      });
    },
    getLink(target) {
      return this.$helpers.router.getOrganizationLink(target);
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === "publicorganizationauth/setClient") {
        this.client = await this.$store
          .dispatch("publicorganizationauth/fetchClient")
          .catch(() => { });
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>