<template>
  <v-footer absolute inset app height="150" width="auto" color="grey lighten-5">
    <v-container>
      <v-row no-gutters class="text-caption" align="center" justify="center">
        Copyright © {{ new Date().getFullYear() }}
        <v-btn to="/" text x-small>monplanning</v-btn> | Tous droits réservés
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>