import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.options.color,"dark":"","dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))])],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pa-4",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('yes')))]),_c(VBtn,{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('no')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }