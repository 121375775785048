import stringhelper from '../helpers/stringhelper'
import rulehelper from '../helpers/rulehelper'
import snackbarhelper from '../helpers/snackbarhelper'
import datehelper from '../helpers/datehelper'
import routerhelper from '../helpers/routerhelper'
import inthelper from '../helpers/inthelper'
import displayhelper from '../helpers/displayhelper'
import organizationhelper from '../helpers/organizationhelper'

const Helpers = {
    install(Vue, /*options*/) {
        Vue.prototype.$helpers = {
            get string() {
                return stringhelper;
            },
            get rule() {
                return rulehelper;
            },
            get snackbar() {
                return snackbarhelper;
            },
            get date() {
                return datehelper;
            },
            get router() {
                return routerhelper;
            },
            get int() {
                return inthelper;
            },
            get display() {
                return displayhelper;
            },
            get organization() {
                return organizationhelper;
            }
        }
    }
}

export default Helpers