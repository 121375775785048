import PublicOrganizationService from '../services/publicorganizationservice';
import router from '@/router'

function getOrganizationUrl() {
    return router.currentRoute.params.organizationUrl;
}

function getLocalStorageName() {
    return `access_token[${getOrganizationUrl()}]`;
}

const initialState = {
    accessToken: null,
    client: null,
};

export const publicorganizationauth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit, dispatch, state }, client) {
            try {
                const { data } = await PublicOrganizationService.login(
                    getOrganizationUrl(),
                    client
                );

                if (data && data.accessToken) {
                    localStorage.setItem(
                        getLocalStorageName(),
                        data.accessToken
                    );

                    await commit('loginSuccess', data.accessToken);

                    await dispatch('fetchClient');

                    return Promise.resolve(state.client);
                }

                commit('loginFailure');

                return Promise.reject('no token');

            } catch (error) {
                commit('loginFailure');
                return Promise.reject(error);
            }
        },
        async fetchAccessToken({ commit }) {
            const accessToken = localStorage.getItem(getLocalStorageName());

            await commit('setAccessToken', accessToken);

            return accessToken ? Promise.resolve(accessToken) : Promise.reject('No access token');
        },
        async fetchClient({ dispatch, commit, state }) {
            if (!await dispatch('fetchAccessToken')) return Promise.reject('No access token');

            if (state.client) {
                return Promise.resolve(state.client)
            }

            try {
                const data = await PublicOrganizationService.getCurrentClient(getOrganizationUrl());

                await commit('setClient', data.data);

                return Promise.resolve(data.data)
            }
            catch (error) {
                throw new Error(`fetchClient ${error}`);
            }
        },
        async resetAndFetchClient({ commit, dispatch, state }) {
            if (!state.accessToken) return Promise.reject('No access token');

            await commit('setClient', null);

            return dispatch('fetchClient');
        },
        async resetPassword({ commit }, login) {
            return PublicOrganizationService.resetPassword(getOrganizationUrl(), login).then(
                async () => {
                    commit('resetPasswordSuccess');
                    return Promise.resolve(login);
                },
                error => {
                    commit('resetPasswordFailure');
                    return Promise.reject(error);
                }
            );
        },
        async resetPasswordFromToken({ commit }, payload) {
            return PublicOrganizationService.resetPasswordFromToken(getOrganizationUrl(), payload.token, payload.password).then(
                async () => {
                    commit('resetPasswordSuccess');
                    return Promise.resolve(payload);
                },
                error => {
                    commit('resetPasswordFailure');
                    return Promise.reject(error);
                }
            );
        },
        async logout({ commit }) {
            localStorage.removeItem(getLocalStorageName());

            await commit('setClient', null);

            commit('logout');
        },
        register({ commit }, client) {
            return PublicOrganizationService.register(getOrganizationUrl(), client).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        setClient(state, client) {
            state.client = client;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        loginSuccess(state, accessToken) {
            state.accessToken = accessToken;
        },
        loginFailure(state) {
            state.accessToken = null;
        },
        logout(state) {
            state.client = null;
            state.accessToken = false;
        },
        registerSuccess(state) {
            state.accessToken = null;
        },
        registerFailure(state) {
            state.accessToken = null;
        },
        resetPasswordSuccess(state) {
            state.accessToken = null;
        },
        resetPasswordFailure(state) {
            state.accessToken = null;
        }
    },
    getters: {
        getAccessToken(state) {
            return state?.accessToken;
        },
        isLogged(state) {
            return state?.accessToken;
        }
    }
};