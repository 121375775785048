import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[(_vm.user && _vm.user.main)?_c(VBtn,{staticClass:"pl-1 pr-1 font-weight-bold",attrs:{"text":"","to":"/admin/my-organization"}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-cog")]),_vm._v(_vm._s(_vm.$t("my-activity")))],1):_vm._e()],1),(_vm.organizationUrl)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"pl-1 pr-1 text-caption",attrs:{"text":"","small":"","to":'/' + _vm.organizationUrl}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("view-my-public-page")))],1)]}}],null,false,1151689543)},[_c('span',[_vm._v(_vm._s(_vm.fullOrganizationUrl))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }