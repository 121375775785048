import i18n from '../i18n';
import stringhelper from '../helpers/stringhelper'

const getDefaultState = () => {
    return { snack: {} };
};

export const snackbar = {
    namespaced: true,
    name: "snackbar",
    state: getDefaultState(),
    actions: {
        show({ commit }, payload) {
            commit('show', payload);
        },
        showSuccess({dispatch} , message) {
            dispatch('show',  {message: message, color: 'success'});
        },
        showError({dispatch} , message) {
            dispatch('show',  {message: message, color: 'orange lighten-2'});
        },
        showInfo({dispatch} , message) {
            dispatch('show',  {message: message, color: 'info'});
        },
        showWarning({dispatch} , message) {
            dispatch('show',  {message: message, color: 'warning'});
        },
        showErrorCode({dispatch} , code) {
            const message = i18n.t('errors.' + code);

            dispatch('showError', stringhelper.capitalize(message));
            
        },
    },
    mutations: {
        show(state, payload) {
            state.snack.message = payload.message;
            state.snack.color = payload.color;
        },
        resetState(state) {
            state.snack = {};
        }
    }
};