import i18n from '../i18n'

var helper = {
    capitalizeI18N: (t, options = {}) => {
        return (options !== undefined && options != null && options.required) ? helper.capitalize(i18n.t(t)) + ' *' : helper.capitalize(i18n.t(t));
    },
    capitalize: (s, options = {}) => {
        if (typeof s !== 'string') return '';

        const required = options?.required ?? false;

        return `${s.charAt(0).toUpperCase() + s.slice(1)} ${required ? '*' : ''}`.trim();
    },
    getClientFullName: (client) => {
        const fullName = (
            (client.firstName ?? "") +
            " " +
            (client.lastName ?? "")
        ).trim();

        return client.companyName
            ? fullName
                ? `${fullName} (${client.companyName})`
                : client.companyName
            : fullName;
    }
};

export default helper;