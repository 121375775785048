import { httpAuthAdmin } from '../plugins/http'

class MembershipService {
  get(id) {
    return httpAuthAdmin().get(`/memberships/${id}`);
  }
  getAll() {
    return httpAuthAdmin().get(`/memberships`);
  }
  getAllAvailable(search, lessonId) {
    return httpAuthAdmin().get(`/memberships/available?search=${search}&lesson_id=${lessonId}`);
  }
  getAllExpiring({ from, to, nbUnitLeft }) {
    return httpAuthAdmin().get(`/memberships/expiring?dateBegin=${from}&dateEnd=${to}&nbUnitLeft=${nbUnitLeft}`);
  }
  save(data) {
    return data.id == null ? httpAuthAdmin().post(`/memberships`, data) : httpAuthAdmin().put(`/memberships/${data.id}`, data);
  }
  invoice(id) {
    return httpAuthAdmin().get(`/memberships/${id}/invoice`);
  }
  delete(data) {
    return httpAuthAdmin().delete(`/memberships/${data.id}`);
  }
}

export default new MembershipService();