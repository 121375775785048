<template>
  <div>
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">
        <!-- <v-btn
          text
          :to="getLink('home')"
          :ripple="false"
          id="no-background-hover"
          class="mr-10"
          style="text-transform: none !important"
          color="primary"
          large
          >monplanning</v-btn
        > -->
        <v-row align="center" class="ml-0">
          <v-btn text :to="getLink('home')" :ripple="false" id="no-background-hover" class=" pl-0"
            style="text-transform: none !important" color="primary" large>
            <v-img src="/static/monplanning.svg" width="150" max-width="150" max-height="40" />
          </v-btn>

          <v-spacer class="d-lg-none" />
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-lg-none"></v-app-bar-nav-icon>

          <v-btn text :to="getLink('home')" class="mr-1 d-none d-lg-flex">Accueil</v-btn>

          <!-- <SportBarButton /> -->

          <v-btn text :to="getLink('features')" class="mr-1 d-none d-lg-flex">Pourquoi monplanning ?</v-btn>

          <v-btn text :to="getLink('pricing')" class="mr-1 d-none d-lg-flex">Tarifs</v-btn>
          <v-btn text :to="getLink('blog')" class="mr-1 d-none d-lg-flex" v-if="showBlog">Blog</v-btn>
          <v-btn text :to="getLink('helpcenter')" class="mr-1 d-none d-lg-flex">Centre d'aide</v-btn>
          <v-btn text :to="getLink('contact')" class="mr-1 d-none d-lg-flex">Contact</v-btn>
          <!-- <v-btn text :to="getLink('about')">A propos</v-btn> -->

          <v-spacer class="d-none d-lg-flex"></v-spacer>


          <!-- <v-responsive max-width="280" class="text-right d-none d-lg-flex mr-2"> -->
          <div class="text-right d-none d-lg-flex mr-3">
            <v-btn color="primary" text :to="getLink('login')">{{
              "Mon compte"
              }}</v-btn>

            <v-badge v-if="!user" :content="1" :value="1" color="deep-orange" overlap>
              <v-btn v-if="!user" outlined color="deep-orange" :to="getLink('register')" class="ml-1">
                commencer
              </v-btn>
            </v-badge>
          </div>

          <!-- </v-responsive> -->

        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary right app>
      <v-list>
        <v-list-item-group v-model="group">
          <v-list-item>
            <v-list-item-content class="d-flex justify-center"><v-btn text
                :to="getLink('home')">Accueil</v-btn></v-list-item-content>
          </v-list-item>

          <!-- <SportSmallBarButton /> -->

          <v-list-item :to="getLink('features')" link>
            <v-list-item-content class="d-flex justify-center"><v-btn text :to="getLink('features')">Pourquoi
                monplanning
                ?</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item :to="getLink('pricing')" link>
            <v-list-item-content class="d-flex justify-center"><v-btn text
                :to="getLink('pricing')">Tarifs</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item :to="getLink('blog')" link v-if="showBlog">
            <v-list-item-content class="d-flex justify-center"><v-btn text
                :to="getLink('blog')">Blog</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item :to="getLink('helpcenter')" link>
            <v-list-item-content class="d-flex justify-center"><v-btn text :to="getLink('helpcenter')">Centre
                d'aide</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item :to="getLink('contact')" link>
            <v-list-item-content class="d-flex justify-center"><v-btn text
                :to="getLink('contact')">Contact</v-btn></v-list-item-content>
          </v-list-item>

          <v-divider class="mt-1 mb-1" />

          <v-list-item :to="getLink('login')" link color="primary">
            <v-list-item-content class="d-flex justify-center"><v-btn color="primary" text :href="getLink('login')">Mon
                compte</v-btn></v-list-item-content>
          </v-list-item>

          <v-list-item :to="getLink('register')" v-if="!user" link color="deep-orange">

            <v-list-item-content class="d-flex justify-center">
              <v-badge v-if="!user" :content="1" :value="1" color="deep-orange" inline>
                <v-btn outlined color="deep-orange" :href="getLink('register')">commencer</v-btn>
              </v-badge>
            </v-list-item-content>

          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import SportBarButton from "@/components/Public/SportBarButton";
// import SportSmallBarButton from "@/components/Public/SportSmallBarButton";

export default {
  components: {
    // SportBarButton,
    // SportSmallBarButton,
  },
  data: () => ({
    drawer: false,
    group: "",
    user: null,
    showBlog: true,
  }),
  computed: {},
  async mounted() {
    try {
      this.user = await this.$store
        .dispatch("adminauth/fetchUser")
        .catch(() => { });
    } catch (error) {
      console.dir(error);
    }
  },
  methods: {
    getLink(target) {
      return target == "login" || target == "register"
        ? this.$helpers.router.getAdminLink(target)
        : this.$helpers.router.getPublicLink(target);
    },
  },
};
</script>