import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from "../i18n"

import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'


Vue.use(Vuetify);


const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: { fr, en },
        current: i18n.locale,
        //t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
        themes: {
            light: {
                primary: '#F1AA67',
                secondary: '#ff9800',
                accent: '#cddc39',
                error: '#f44336',
                warning: '#ff5722',
                info: '#607d8b',
                success: '#8bc34a',
                background: colors.grey.lighten3,
            },
        },
    },
});

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'md'
})

export default vuetify