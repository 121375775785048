import Vue from 'vue'
import VueRouter from 'vue-router'
// import i18n from "../i18n"
import routes from "./routes"
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// BLOG routes
import blogservice from "../services/blogservice"

function load(component) {
  return () => import(`../views/${component}.vue`)
}

blogservice.getAll().then(result => {
  for (var i = 0; i < result.data.length; i++) {
    const blog = result.data[i];
    router.addRoute("public", {
      name: 'blog|' + blog.slugUrl,
      path: blog.slugUrl,
      component: load('Public/BlogItem'),
    })
  }
});



router.beforeEach(async (to, from, next) => {

  // let locale = to.params.lang;

  // console.log('locale : ' + locale + ' to : ' + to.path);

  // const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');

  // if (!supported_locales.includes(locale)) {
  //   locale = 'fr';
  // }

  // if (i18n.locale !== locale) {
  //   i18n.locale = locale;
  // }

  if (to.fullPath.includes('/admin/')) {

    const user = await store.dispatch(
      "adminauth/fetchUser"
    ).catch(() => { });

    if (to.matched.some(record => record.meta.authRequired)) {

      if (user == null) {

        return next({
          path: '/admin/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        const organization = await store.dispatch(
          "organization/fetchOrganization"
        );

        const myOrganizationPath = '/admin/my-organization';

        if (organization.validityDateEnd != null && new Date(organization.validityDateEnd) < new Date() && to.path !== myOrganizationPath && to.name !== "organization-payment") {
          console.dir('validity ended !')

          return next({ path: myOrganizationPath })
        } else {

          return next();
        }
        // const firstLoginPath = '/' + locale + '/' + i18n.t('routes.first-login');
        // console.log('current organization name ' + store)
        // console.dir(store.getters["organization/getOrganization"]);
        // if ((undefined === undefined) &&
        //   to.path !== firstLoginPath) {
        //   next({
        //     path: firstLoginPath,
        //     params: { nextUrl: '/' }
        //   });
        // } else {
        //   next();
        // }
      }
    } else {
      if (user != null) {
        return next({
          path: '/admin/dashboard',
          params: { nextUrl: '/' }
        });
      } else {
        return next();
      }
    }
  }

  next();
});

export default router
